.root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

/* Apply pulse animation to the image */
.pulse-image {
  animation: pulse 2s infinite alternate;
  /* Alternate the animation direction */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}


.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

.landing {
  background-image: url("./assets/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.journal {
  background-image: url("./assets/journalbanner1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.rooms {
  background-image: url("./assets/room.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.management {
  background-image: url("./assets/managementteam.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

.reservation {
  background-image: url("./assets/Receptionarea.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.ameneties {
  background-image: url("./assets/Roofdeck.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.skylounge {
  background-image: url("./assets/Sky\ Lounge/4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.coffeelounge {
  background-image: url("./assets/Coffee Lounge/CLhaha.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

.zermatt {
  background-image: url("./assets/Zermatt/restaurant2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.spa {
  background-image: url("./assets/spabg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.object-cover-start-left {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  /* Position the image to the left */
}

.stick {
  position: sticky;
  top: 100px;


  font-size: 20px;
}

.revealLeft {
  display: block;
  animation: revealLeft 1s cubic-bezier(0.77, 0, 0.175, 1);
}

.revealRight {
  display: block;
  animation: revealRight 1s cubic-bezier(0.77, 0, 0.175, 1);
}

.revealTop {
  display: block;
  animation: revealTop 1s cubic-bezier(0.77, 0, 0.175, 1);
}

.revealBottom {
  display: block;
  animation: revealBottom 1s cubic-bezier(0.77, 0, 0.175, 1);
}

.revealBottom2 {
  display: block;
  animation: revealBottom 1.2s cubic-bezier(0.77, 0, 0.175, 1);
}

.revealBottom3 {
  display: block;
  animation: revealBottom 1.3s cubic-bezier(0.77, 0, 0.175, 1);
}

.revealBottom4 {
  display: block;
  animation: revealBottom 1.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.revealBottom5 {
  display: block;
  animation: revealBottom 1.5s cubic-bezier(0.77, 0, 0.175, 1);
}

.fade {
  animation: fadeIn 3s;
}

.fade2 {
  animation: fadeIn 1s;
}


@keyframes revealTop {
  0% {
    transform: translate(0, -100%);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes revealBottom {
  0% {
    transform: translate(0, 100%);
    opacity: 0
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes revealLeft {
  0% {
    transform: translate(-100%, 0);
    opacity: 0
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes revealRight {
  0% {
    transform: translate(100%, 0);
    opacity: 0
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.btn-book-now {
  background-color: #f39c12;  /* Custom background color */
  color: white;
  font-weight: bold;
  border-radius: 25px;
  padding: 12px 24px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  text-align: center;
}

.btn-book-now:hover {
  background-color: #e67e22; /* Hover effect */
  cursor: pointer;
}

/* Optional: Focus state for accessibility */
.btn-book-now:focus {
  outline: 2px solid #f39c12;
  outline-offset: 2px;
}





.blur-md {
  filter: blur(10px);
  transition: filter 1s ease-out;
}

/* Smooth transition for opacity */
.opacity-0 {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.opacity-100 {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

/*Hotspot bounce animation for 360 pages*/
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2); /* Scale up to 120% */
  }
  100% {
    transform: scale(1);
  }
}

.hotspot-pulse {
  animation: pulse 1s ease-in-out; /* Pulse animation with 1s duration */
}


/*Hotspot bounce animation for 360 pages while displayed*/
@keyframes jump {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}


.hotspot-jump {
  animation: jump 1.5s ease-in-out infinite;
}

@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Adjust the jump height here */
  }
}

.opacity-0 {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.opacity-100 {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.hotspot-animation {
  animation: hotspot-animation 0.5s ease-out forwards;
}

@keyframes hotspot-animation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2); /* Adjust the scale size as needed */
  }
}

/* Add some styles for better page layout */
body {
  margin: 0;
  overflow: auto;
}

canvas {
  display: block;
  width: 100%;
  height: 100vh; /* Makes the canvas take up full viewport height */
}
